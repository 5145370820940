import { faUserSecret } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Card, Container, Grid } from '@mui/material';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { api } from '../api';
import BoxLoader from './misc/BoxLoader';

function Login() {
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    setLoading(true);
    toast.dismiss();
    api.post('/auth/login', {}, {})
      .then((response) => {
        window.location.href = response.data;
      })
      .catch((err) => {
        console.error(err);
        console.log(err.response);
        setLoading(false);
        toast.error('Une erreur est survenue, veuillez réessayer.', { duration: 3000 });
      });
  };

  return (
    <Container>
      <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={6} md={4}>
          <BoxLoader loading={loading}>
            <Card variant="outlined" sx={{ p: 3 }}>
              <Box sx={{ pb: 4 }}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <FontAwesomeIcon
                      icon={faUserSecret}
                      style={{
                        '--fa-primary-color': '#000000',
                        '--fa-secondary-color': '#ebaf00',
                        '--fa-secondary-opacity': 1,
                        margin: 'auto,'
                      }}
                      size="10x"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="warning"
                    sx={{ fontWeight: 600, fontSize: '120%' }}
                    onClick={handleLogin}
                    disabled={loading}
                    size="lg"
                    large
                  >
                    Se connecter avec Plex
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </BoxLoader>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Login;
