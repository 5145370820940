/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export const url = process.env.REACT_APP_API_URL || 'http://localhost:3127';

export const createUrl = (endpoint) => `${url}${!url.endsWith('/')
  ? '/'
  : ''}${endpoint.startsWith('/') ? endpoint.slice(1) : endpoint}`;

const isAbsolute = (endpoint) => /^https?\/\//.test(endpoint);

axios.defaults.withCredentials = false;
axios.defaults.baseURL = url;

/**
 * Send a request to API
 * @param method
 * @param endpoint
 * @param params
 * @param data
 * @param headers
 * @param responseType
 * @returns {AxiosPromise}
 */
const request = (method, endpoint, params = {}, data = {}, headers = {}, responseType = 'json') => axios.request({
  baseURL: !isAbsolute(endpoint) ? url : '',
  method,
  url: endpoint,
  params,
  data,
  headers,
  responseType,
}).catch((error) => {
  if (error && error.response && error.response.data && error.response.data.detail ===
    'Informations d\'authentification non fournies.') {
    if (endpoint !== '/whoami/') {
      window.location.href = '/login/';
    }
  }
  throw error;
});

const requests = {
  /**
   * Send a GET request to API
   * @param endpoint
   * @param params
   * @param headers
   * @returns {AxiosPromise}
   */
  get: (endpoint, params = {}, headers = {}, responseType = 'json') => request('get', endpoint, params, undefined, headers, responseType),
  /**
   * Send a POST request to API
   * @param endpoint
   * @param params
   * @param data
   * @param headers
   * @param responseType
   * @returns {AxiosPromise}
   */
  post: (endpoint, params = {}, data = {}, headers = {}, responseType = 'json') => request('post', endpoint, params, data, headers, responseType),
  /**
   * Send a PUT request to API
   * @param endpoint
   * @param params
   * @param data
   * @param headers
   * @returns {AxiosPromise}
   */
  put: (endpoint, params = {}, data = {}, headers = {}) => request('put', endpoint, params, data, headers),
  /**
   * Send a PATCH request to API
   * @param endpoint
   * @param params
   * @param data
   * @param headers
   * @returns {AxiosPromise}
   */
  patch: (endpoint, params = {}, data = {}, headers = {}) => request('patch', endpoint, params, data, headers),
  /**
   * Send a DELETE request to API
   * @param endpoint
   * @param params
   * @param headers
   * @returns {AxiosPromise}
   */
  delete: (endpoint, params = {}, headers = {}) => request('delete', endpoint, params, undefined, headers),
  /**
   * Send a GET request to download from API
   * @param endpoint
   * @param params
   * @param headers
   * @returns {AxiosPromise}
   */
  download: (endpoint, params = {}, headers = {
    'Content-Type': 'application/json',
    // Accept: 'application/pdf',
  }) => request('get', endpoint, params, {}, headers, 'arraybuffer'),
};

export const api = requests;
