import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay';

function BoxLoader({
  children,
  loading,
}) {

  return (
    <LoadingOverlay
      active={loading}
      spinner={(
        <CircularProgress variant="indeterminate" />
      )}
    >
      {children}
    </LoadingOverlay>
  );
}

BoxLoader.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default BoxLoader;
