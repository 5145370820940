import {
  faCheckCircle,
  faCircleCheck,
  faCircleXmark,
  faCloudArrowDown,
  faFilm,
  faFilmSlash,
  faMagnifyingGlassArrowRight,
  faRectangle,
  faSearch,
  faTv,
  faTvRetro,
  faUserSecret,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  Dialog, DialogActions,
  DialogContent, DialogContentText,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { api } from '../api';
import ca from '../assets/flags/ca.svg';
import en from '../assets/flags/en.svg';
import fr from '../assets/flags/fr.svg';
import frt from '../assets/flags/frt.svg';
import multiCa from '../assets/flags/multi-ca.svg';
import multiFr from '../assets/flags/multi-fr.svg';
import multiFrt from '../assets/flags/multi-frt.svg';
import { selectAuth } from '../redux/auth';
import BoxLoader from './misc/BoxLoader';

const qualityColors = {
  'CAM': '#263238',
  'SD': '#bcaaa4',
  'HD': '#00bcd4',
  'FHD': '#4caf50',
  '4K': '#ffd740',
};

const flags = {
  'fr': fr,
  'frt': frt,
  'en': en,
  'ca': ca,
  'multi-fr': multiFr,
  'multi-frt': multiFrt,
  'multi-ca': multiCa,
};

const typeTitles = {
  'movie': 'de film',
  'tvshow': 'de série',
  'tvprogram': 'd\'émission',
};

const displayQuality = (qualityProperties) => {
  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: qualityProperties
          ? qualityColors[qualityProperties.shortDisplay]
          : '#b0bec5',
        borderRadius: 5,
        width: 31,
        height: 22,
      }}
    >
      <span
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontWeight: 'bold',
          fontSize: '70%',
          color: 'white',
        }}
      >
        {qualityProperties ? qualityProperties.shortDisplay : '??'}
      </span>
    </div>
  );
};

const displayFlag = (languageProperties) => {
  return languageProperties && languageProperties.flag ? (
    <img
      src={flags[languageProperties.flag]}
      style={{ width: 31, borderRadius: 5 }}
      alt={languageProperties.flag}
    />
  ) : displayQuality(null, true);
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function Dashboard() {
  const [search, setSearch] = useState('');
  const [type, setType] = useState('movie');
  const [language, setLanguage] = useState('all');
  const [quality, setQuality] = useState('all');
  const [modalOpen, setModalOpen] = useState(false);
  const [oneShotAlertOpen, setOneShotAlertOpen] = useState(false);
  const [downloadList, setDownloadList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const { token } = useSelector(selectAuth);
  let timeout = useRef(null);

  const handleSearchInputChange = (e) => {
    const localSearch = e ? e.target.value : search;
    if (e) setSearch(localSearch);
    clearTimeout(timeout.current);
    if (localSearch.length > 0) {
      timeout.current = setTimeout(() => {
        setLoading(true);
        setResults([]);
        api.post('/search', {}, { auth: token, search: localSearch, type, language, quality })
          .then((res) => {
            setResults(res.data);
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            toast.error(err.response.data || 'Une erreur est survenue');
            setLoading(false);
          });
      }, 1000);
    } else {
      setResults([]);
      setLoading(false);
    }
  };

  const setDownloadUrlStatus = (url, status) => {
    setDownloadList((prevTab) => {
      const tab = prevTab.map((item) => {
        if (item.url === url) {
          return { ...item, status };
        }
        return item;
      });
      return tab;
    });
  };

  const startDownload = async ({ url }, sub = false) => {
    toast.dismiss();
    if (!sub) {
      setLoading(true);
      setModalOpen(true);
    } else {
      setDownloadUrlStatus(url, 'loading');
    }
    try {
      const result = await api.post('/fetch', {}, { auth: token, url, type, isMediaPage: sub });
      if (!sub) {
        if (Array.isArray(result.data) && result.data.length > 0) {
          console.log(result.data);
          setLoading(false);
          setModalOpen(false);
          setDownloadList(result.data);
        } else {
          toast.success('Le téléchargement a débuté !');
          setLoading(false);
          setModalOpen(false);
        }
      } else {
        console.log('OK status');
        setDownloadUrlStatus(url, 'success');
      }
    } catch (err) {
      console.error(err);
      toast.error(err.response.data || 'Une erreur est survenue');
      if (!sub) {
        setLoading(false);
        setModalOpen(false);
      } else {
        setDownloadUrlStatus(url, 'error');
      }
    }
  };

  const startAllDownloads = async () => {
    for (const item of downloadList) {
      startDownload(item, true);
      await sleep(500);
    }
  };

  useEffect(() => {
    handleSearchInputChange();
  }, [type]);

  useEffect(() => {
    handleSearchInputChange();
  }, [language]);

  useEffect(() => {
    handleSearchInputChange();
  }, [quality]);

  return (
    <Container sx={{ pb: 5, pt: 2 }}>
      <Dialog
        open={oneShotAlertOpen}
        onClose={() => setOneShotAlertOpen(false)}
        slotProps={{
          backdrop: {
            sx: {
              background: 'unset',
              backgroundColor: 'default.lighter',
            },
          },
        }}
      >
        <DialogContent sx={{ bgcolor: 'warning.light' }}>
          <DialogContentText>
            Les téléchargements sont temporairement ralentis en raison d'une mise à jour des
            pare-feux anti-piratage de nos partenaires.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ bgcolor: 'warning.light' }}>
          <Grid container justifyContent="center">
            <Grid item>
              <IconButton
                onClick={() => setOneShotAlertOpen(false)}
                color="default"
                autoFocus
              >
                <FontAwesomeIcon icon={faCheckCircle} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        disableEscapeKeyDown
        disableAutoFocus
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <Typography
            component="h2"
            id="modal-title"
            variant="h4"
            textColor="inherit"
            fontWeight="lg"
            align="center"
            mb={1}
          >
            Chargement en cours...
          </Typography>
          <Typography id="modal-desc" textColor="text.tertiary" align="center">
            Veuillez patienter un instant.
          </Typography>
          <LinearProgress variant="indeterminate" sx={{ mt: 4, mb: 1 }} />
        </Paper>
      </Dialog>
      <Dialog
        open={downloadList && downloadList.length > 0}
        onClose={() => setDownloadList([])}
        disableEscapeKeyDown
        disableAutoFocus
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            maxHeight: '80vh',
            overflow: 'auto',
          }}
        >
          {/* <ModalClose
            variant="outlined"
          /> */}
          <Typography
            component="h2"
            id="modal-title"
            variant="h5"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Plusieurs parties sont disponibles :
          </Typography>
          <Box pb={2}>
            <Grid container justifyContent="flex-end">
              <Grid>
                <Button
                  color="warning"
                  variant="contained"
                  onClick={startAllDownloads}
                  disabled={!downloadList.every((item) => !item.status)}
                >
                  Tout télécharger
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={1}>
            {downloadList.map((item) => (
              <Grid xs={12} key={item.url}>
                <Paper>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      {item.title}
                      {item.status === 'success' && (
                        <Typography
                          sx={{
                            display: 'inline',
                            color: 'success.main',
                            ml: 1,
                          }}
                        >
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </Typography>
                      )}
                      {item.status === 'error' && (
                        <Typography
                          sx={{
                            display: 'inline',
                            color: 'error.main',
                            ml: 1,
                          }}
                        >
                          <FontAwesomeIcon icon={faCircleXmark} />
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs />
                    <Grid item>
                      <Button
                        size="md"
                        variant="contained"
                        color={item.status !== 'success' ? 'success' : 'default'}
                        onClick={() => startDownload(item, true)}
                        loading={item.status === 'loading'}
                        disabled={item.status === 'success'}
                      >
                        {item.status === 'success' ? 'Téléchargé' : 'Télécharger'}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      {item.status === 'success' && (
                        <LinearProgress
                          variant="determinate"
                          color="success"
                          determinate
                          value={100}
                        />
                      )}
                      {item.status === 'error' && (
                        <LinearProgress
                          variant="determinate"
                          color="error"
                          determinate
                          value={100}
                        />
                      )}
                      {item.status === 'loading' && (
                        <LinearProgress variant="indeterminate" color="primary" />
                      )}
                      {(!item.status || item.status === '') && (
                        <LinearProgress
                          variant="determinate"
                          color="default"
                          determinate
                          value={0}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Dialog>
      <Box sx={{ pb: 2 }}>
        <Grid container justifyContent="center">
          <Grid item>
            <FontAwesomeIcon
              icon={faUserSecret}
              style={{
                '--fa-primary-color': '#000000',
                '--fa-secondary-color': '#ebaf00',
                '--fa-secondary-opacity': 1,
                margin: 'auto,'
              }}
              size="10x"
            />
          </Grid>
        </Grid>
      </Box>
      <BoxLoader loading={loading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              placeholder="Rechercher un film, une série, une émission TV..."
              value={search}
              onChange={handleSearchInputChange}
              startDecorator={(
                <FontAwesomeIcon icon={faSearch} />
              )}
              fullWidth
              small
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="Type"
              value={type}
              onChange={(event) => setType(event.target.value)}
              InputProps={{
                sx: {
                  '& .MuiSelect-select': {
                    display: 'flex',
                  },
                },
              }}
              fullWidth
              small
              select
            >
              <MenuItem
                value="movie"
              >
                Films
                <span style={{ flexGrow: 1 }} />
                <span className="fa-layers fa-fw fa-2x" style={{ height: 'unset' }}>
                  <FontAwesomeIcon icon={faRectangle} color="#00bcd4" />
                  <FontAwesomeIcon icon={faFilm} transform="shrink-7" />
                </span>
              </MenuItem>
              <MenuItem
                value="tvshow"
              >
                Séries
                <span style={{ flexGrow: 1 }} />
                <span className="fa-layers fa-fw fa-2x" style={{ height: 'unset' }}>
                  <FontAwesomeIcon icon={faRectangle} color="#4caf50" />
                  <FontAwesomeIcon icon={faTv} transform="shrink-8" />
                </span>
              </MenuItem>
              <MenuItem
                value="tvprogram"
              >
                Emissions TV
                <span style={{ flexGrow: 1 }} />
                <span className="fa-layers fa-fw fa-2x" style={{ height: 'unset' }}>
                  <FontAwesomeIcon icon={faRectangle} color="#ba68c8" />
                  <FontAwesomeIcon icon={faTvRetro} transform="shrink-8" />
                </span>
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="Langue"
              value={language}
              onChange={(event) => setLanguage(event.target.value)}
              InputProps={{
                sx: {
                  '& .MuiSelect-select': {
                    display: 'flex',
                  },
                },
              }}
              fullWidth
              select
            >
              <MenuItem
                value="all"
              >
                Tout afficher
                <span style={{ flexGrow: 1 }} />
                {displayFlag({ flag: 'multi-frt' })}
              </MenuItem>
              <MenuItem
                value="en"
              >
                Voix originale
                <span style={{ flexGrow: 1 }} />
                {displayFlag({ flag: 'en' })}
              </MenuItem>
              <MenuItem
                value="fr"
              >
                Voix française
                <span style={{ flexGrow: 1 }} />
                {displayFlag({ flag: 'frt' })}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              label="Qualité"
              value={quality}
              onChange={(event) => setQuality(event.target.value)}
              InputProps={{
                sx: {
                  '& .MuiSelect-select': {
                    display: 'flex',
                  },
                },
              }}
              fullWidth
              select
            >
              <MenuItem
                value="all"
              >
                Tout afficher
                <span style={{ flexGrow: 1 }} />
                {displayQuality()}
              </MenuItem>
              <MenuItem
                value="4k"
              >
                UHD / 2160p
                <span style={{ flexGrow: 1 }} />
                {displayQuality({ shortDisplay: '4K' })}
              </MenuItem>
              <MenuItem
                value="1080p"
              >
                FHD / 1080p
                <span style={{ flexGrow: 1 }} />
                {displayQuality({ shortDisplay: 'FHD' })}
              </MenuItem>
              <MenuItem
                value="720p"
              >
                HD / 720p
                <span style={{ flexGrow: 1 }} />
                {displayQuality({ shortDisplay: 'HD' })}
              </MenuItem>
              <MenuItem
                value="other"
              >
                SD / 480p
                <span style={{ flexGrow: 1 }} />
                {displayQuality({ shortDisplay: 'SD' })}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={12}>
            <Box>
              {results.length > 0 ? (
                <Grid container spacing={2}>
                  {results.map((item) => (
                    <Grid item xs={12} sm={6} lg={3} key={item.url}>
                      <Card
                        variant="outlined"
                        sx={{
                          pr: isMobile ? '0 !important' : null,
                          pb: !isMobile ? '0 !important' : null,
                        }}
                      >
                        <Grid container>
                          <Grid item xs={!isMobile ? 12 : undefined}>
                            <Box
                              sx={{
                                position: 'relative',
                                width: isMobile ? '90px !important' : '100%',
                              }}
                            >
                              <img
                                src={item.image}
                                srcSet={item.image}
                                loading="lazy"
                                alt=""
                                style={{
                                  opacity: 0.7,
                                  width: isMobile ? '90px !important' : '100%',
                                  aspectRatio: '3/4',
                                }}
                              />
                              <BrowserView>
                                <Button
                                  size="lg"
                                  large
                                  variant="contained"
                                  color="success"
                                  sx={{
                                    position: 'absolute',
                                    zIndex: 2,
                                    borderTopLeftRadius: '50%',
                                    borderBottomLeftRadius: '50%',
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    px: 2,
                                    py: 2,
                                    minWidth: 0,
                                    right: 0,
                                    bottom: 0,
                                    transform: 'translateY(50%)',
                                  }}
                                  onClick={() => startDownload(item)}
                                >
                                  <FontAwesomeIcon icon={faCloudArrowDown} />
                                </Button>
                              </BrowserView>
                            </Box>
                          </Grid>
                          <BrowserView renderWithFragment>
                            <Card
                              variant="plain"
                              sx={{
                                borderTopLeftRadius: 0,
                                borderBottomRightRadius: 0,
                                position: 'absolute',
                                zIndex: 2,
                                right: 0,
                                border: 0,
                                boxShadow: 'unset',
                                borderTopRightRadius: '10px',
                                top: 0,
                                p: 1,
                              }}
                            >
                              <Grid container direction="column" spacing={0}>
                                <Grid item sx={{ height: 29 }}>
                                  {displayFlag(item.languageProperties)}
                                </Grid>
                                <Grid item>
                                  {displayQuality(item.qualityProperties)}
                                </Grid>
                              </Grid>
                            </Card>
                          </BrowserView>
                          <BrowserView renderWithFragment>
                            <Grid item xs={12}>
                              <Divider inset="context" />
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: 'md',
                                  mt: '2 !important',
                                  mb: '2 !important',
                                  py: 1,
                                  px: 1,
                                  fontWeight: 'bold',
                                }}
                                style={{ textOverflow: 'ellipsis' }}
                                noWrap
                              >
                                {item.title}
                              </Typography>
                            </Grid>
                          </BrowserView>
                          <MobileView renderWithFragment>
                            <Grid item xs>
                              <Grid
                                container
                                direction="column"
                                spacing={0}
                                justifyContent="space-between"
                                sx={{
                                  height: '100%',
                                  padding: 0,
                                  mb: -2,
                                }}
                              >
                                <Grid item xs>
                                  <Grid
                                    container
                                    direction="column"
                                    spacing={0}
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                      height: '100%',
                                    }}
                                  >
                                    <Grid item>
                                      <Typography
                                        variant="body1"
                                        sx={{ fontSize: 'md', px: .5, fontWeight: 'bold' }}
                                        style={{ textAlign: 'center' }}
                                      >
                                        {item.title}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Paper
                                    sx={{
                                      display: 'flex',
                                      gap: 1.5,
                                      py: 1.5,
                                      px: 1,
                                      bgcolor: 'background.neutral',
                                      alignItems: 'center',
                                      borderRadius: 0,
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{ fontWeight: 'md', color: 'text.secondary' }}
                                    >
                                      {item.languageProperties
                                        ? item.languageProperties.display
                                        : item.language}
                                    </Typography>
                                    <div style={{ flexGrow: 1 }} />
                                    <Typography
                                      variant="body2"
                                      sx={{ fontWeight: 'md', color: 'text.secondary' }}
                                      noWrap
                                    >
                                      {item.qualityProperties
                                        ? item.qualityProperties.display.replace(/^\w+\s\((.*)\)$/, '$1')
                                        : item.quality}
                                    </Typography>
                                  </Paper>
                                </Grid>
                              </Grid>
                            </Grid>
                          </MobileView>
                          <BrowserView renderWithFragment>
                            <Grid item xs={12}>
                              <Divider inset="context" />
                              <Paper
                                sx={{
                                  display: 'flex',
                                  gap: 1.5,
                                  py: 1.5,
                                  px: 1,
                                  bgcolor: 'background.neutral',
                                  alignItems: 'center',
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 'md', color: 'text.secondary' }}
                                >
                                  {item.languageProperties
                                    ? item.languageProperties.display
                                    : item.language}
                                </Typography>
                                <div style={{ flexGrow: 1 }} />
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 'md', color: 'text.secondary' }}
                                >
                                  {item.qualityProperties
                                    ? item.qualityProperties.display
                                    : item.quality}
                                </Typography>
                              </Paper>
                            </Grid>
                          </BrowserView>
                          <MobileView renderWithFragment>
                            <Grid item>
                              <Grid
                                item
                                container
                                direction="column"
                                spacing={1}
                                justifyContent="space-between"
                                sx={{
                                  height: '100%',
                                  mt: '0 !important',
                                }}
                              >
                                <Grid item xs>
                                  <Grid
                                    container
                                    direction="column"
                                    spacing={0}
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                      height: '100% !important',
                                    }}
                                  >
                                    <Grid item sx={{ height: 29 }}>
                                      {displayFlag(item.languageProperties)}
                                    </Grid>
                                    <Grid item>
                                      {displayQuality(item.qualityProperties)}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Button
                                    size="lg"
                                    large
                                    variant="contained"
                                    color="success"
                                    sx={{
                                      borderTopLeftRadius: 0,
                                      borderBottomLeftRadius: 0,
                                      borderTopRightRadius: 0,
                                      borderBottomRightRadius: 10,
                                      px: 1.5,
                                      py: 2,
                                      minWidth: 0,
                                    }}
                                    onClick={() => startDownload(item)}
                                  >
                                    <FontAwesomeIcon icon={faCloudArrowDown} />
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </MobileView>
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <>
                  {search === '' ? (
                    <Alert
                      startDecorator={(
                        <FontAwesomeIcon icon={faMagnifyingGlassArrowRight} />
                      )}
                      color="warning"
                    >
                      Veuillez saisir un titre
                      {' '}
                      {typeTitles[type]}
                      {' '}
                      dans la barre de recherche.
                    </Alert>
                  ) : (
                    <Alert
                      startDecorator={(
                        <FontAwesomeIcon icon={faFilmSlash} />
                      )}
                      color="warning"
                    >
                      Aucun média trouvé avec cette recherche.
                    </Alert>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </BoxLoader>
    </Container>
  );
}

export default Dashboard;
