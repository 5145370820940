import { useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../api';
import { login, selectAuth } from '../redux/auth';
import Dashboard from './Dashboard';
import Login from './Login';
import BoxLoader from './misc/BoxLoader';

function Main() {
  const [loading, setLoading] = React.useState(true);
  const { isAuthenticated } = useSelector(selectAuth);
  const theme = useTheme();
  const dispatch = useDispatch();

  const checkAuth = () => {
    setLoading(true);
    api.post('/auth/check')
      .then(() => {
        dispatch(login());
        setLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.status !== 401) {
          toast.error(err.response.data || 'Une erreur est survenue', { id: 'checkAuth' });
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <BoxLoader loading={loading}>
      <div>
        {isAuthenticated ? (
          <Dashboard />
        ) : (
          <Login />
        )}
        <Toaster
          toastOptions={{
            success: {
              duration: 10000,
              style: {
                background: theme.palette.success.main,
                color: 'white',
              },
            },
            error: {
              duration: 10000,
              style: {
                background: theme.palette.error.main,
                color: 'white',
              },
            },
          }}
        />
      </div>
    </BoxLoader>
  );
}

export default Main;
